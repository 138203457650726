import type { NormalizedCacheObject } from '@apollo/client';
import type { GetGenericItemBySection_genericItemBySection_genericItems } from './__generated__/GetGenericItemBySection';
import type { GenericItem as GenericItemFragment } from './__generated__/GenericItem';
import {
  GetProgrammableComponent_programmableComponent_zones,
  GetProgrammableComponent_programmableComponent_zones_component,
} from './__generated__/GetProgrammableComponent';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export { PageType } from './__generated__/globalTypes';
export type {
  GetItem_item_linkedWith_author as AuthorType,
  GetItem_item_mainDataSheet_groups_attributes as DataSheetAttributes,
} from './__generated__/GetItem';

export type { GetItemsForArchive_archive_daysList_groupByDay as DaysList } from './__generated__/GetItemsForArchive';

export type {
  GetPageInfo_pageInfo as PageInfo,
  GetPageInfo_pageInfo_item as Item,
  GetPageInfo_pageInfo_section as Section,
} from './__generated__/GetPageInfo';

export type {
  GetTopic_topicByExternalId_postStream_posts as TopicPost,
  GetTopic_topicByExternalId as TopicInfo,
} from './__generated__/GetTopic';

export type {
  GenericItem_dataSheet as GenericItemDataSheet,
  GenericItem_dataSheet_groups_attributes as GenericItemDataSheetAttributes,
  GenericItem_dataSheet_groups as GenericItemDataSheetGroups,
} from './__generated__/GenericItem';

export type { rateSoftware as RateSoftwareResponse } from './__generated__/rateSoftware';

export type {
  FilterAggs_aggsDataSheetAttributes as AggsDataSheetAttribute,
  FilterAggs_aggsDataSheetAttributes_buckets as AggsDataSheetAttributeBuckets,
  FilterAggs_aggsDataSheetAttributes_dataSheetAttribute as AggsDataSheetAttributeAttr,
} from './__generated__/FilterAggs';

export type GenericItem = GenericItemFragment | GetGenericItemBySection_genericItemBySection_genericItems;
export type Component = GetProgrammableComponent_programmableComponent_zones_component;
export type ProgrammableComponentZone = GetProgrammableComponent_programmableComponent_zones;

export type ApolloState = { [APOLLO_STATE_PROP_NAME]: NormalizedCacheObject };
export type PageProps<T> = T & ApolloState;
