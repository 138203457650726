import { ApolloClient, gql, NormalizedCacheObject, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import type { GetSection, GetSectionVariables } from '../__generated__/GetSection';
import type { GetSections, GetSectionsVariables } from '../__generated__/GetSections';
import type { GetSectionItems, GetSectionItemsVariables } from '../__generated__/GetSectionItems';
import type { LazyQueryHook, UpdateQuery } from './types';
import {
  GetSectionTemplateConfigByUrl,
  GetSectionTemplateConfigByUrlVariables,
} from '../__generated__/GetSectionTemplateConfigByUrl';

export const GET_SECTION_QUERY = gql`
  query GetSection($id: ID!) {
    section(id: $id) {
      id
      title
      url
      layout
      mainParent
      parents
      children
    }
  }
`;

export const GET_SECTION_TEMPLATE_CONFIG_BY_URL = gql`
  query GetSectionTemplateConfigByUrl($url: String) {
    section(url: $url) {
      templateConfig
    }
  }
`;

export const GET_SECTIONS_QUERY = gql`
  query GetSections($ids: [ID!]!) {
    sections(ids: $ids) {
      id
      title
      url
      layout
      mainParent
      parents
      children
    }
  }
`;

export const GET_SECTIONS_ITEM_QUERY = gql`
  query GetSectionItems($id: String!, $from: Int, $size: Int) {
    items(queryBy: { type: section, value: $id }, from: $from, size: $size) {
      id
      title
      summary
      label
      url
      externalLink
      imageId
      layout
      republishedAt
      updatedAt
      productReviewScore
      comments
    }
  }
`;

export const fetchSection = (client: ApolloClient<NormalizedCacheObject>, queryParam: GetSectionVariables) => {
  return client.query<GetSection, GetSectionVariables>({ query: GET_SECTION_QUERY, variables: queryParam });
};

export const fetchSectionTemplateConfigByUrl = (
  client: ApolloClient<NormalizedCacheObject>,
  queryParam: GetSectionTemplateConfigByUrlVariables,
) => {
  return client.query<GetSectionTemplateConfigByUrl, GetSectionTemplateConfigByUrlVariables>({
    query: GET_SECTION_TEMPLATE_CONFIG_BY_URL,
    variables: queryParam,
  });
};
export const useSectionQuery = (options?: QueryHookOptions<GetSection, GetSectionVariables>) => {
  const { loading, error, data } = useQuery<GetSection, GetSectionVariables>(GET_SECTION_QUERY, options);

  return { loading, error, section: data?.section };
};

export const fetchSections = (client: ApolloClient<NormalizedCacheObject>, ids: string[]) => {
  return client.query<GetSections, GetSectionsVariables>({ query: GET_SECTIONS_QUERY, variables: { ids } });
};

export const useSectionsQuery = (options?: QueryHookOptions<GetSections, GetSectionsVariables>) => {
  const { loading, error, data } = useQuery<GetSections, GetSectionsVariables>(GET_SECTIONS_QUERY, options);

  return { loading, error, sections: data?.sections };
};

export const useSectionsItemQuery: LazyQueryHook<GetSectionItems, GetSectionItemsVariables> = (options) => {
  const [fetchData, { called, loading, data, fetchMore, error, networkStatus, client }] = useLazyQuery<
    GetSectionItems,
    GetSectionItemsVariables
  >(GET_SECTIONS_ITEM_QUERY, options);

  const fetchMoreData = async (variables: GetSectionItemsVariables) => {
    const updateQuery: UpdateQuery<GetSectionItems> = (previousResult, { fetchMoreResult }) => ({
      items: [...previousResult.items, ...fetchMoreResult.items],
    });
    await fetchMore({ updateQuery, variables });
  };

  return {
    networkStatus,
    loading,
    error,
    result: data,
    called,
    fetchData,
    fetchMoreData,
    client,
  };
};
